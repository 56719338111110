import * as React from 'react';
import { Checkbox, mergeStyleSets } from '@fluentui/react';
import { myTheme } from '../../styles/theme';
import { LabelComponent } from './labelComponent';
import { L } from '../../lib/abpUtility';

const classNames = mergeStyleSets({
    checkboxLabel: {
        paddingBottom: '7px', 
        borderTopRightRadius: '3px', 
        borderBottomRightRadius: '3px',
        whiteSpace: 'pre-line',
    },
    checkboxInList: {
        marginTop: '15px',
        marginRight: '25px',
    },
    checkboxOptionsWrapper: {
        display: 'flex', 
        flexDirection: 'row', 
        flexWrap: 'wrap', 
        justifyContent: 'flex-start', 
        alignItems: 'center', marginTop: 5,
    }
});

export interface ICheckBoxProps {
    label: string;
    disabled?: boolean;
    required?: boolean;
    options?: any[],
    validationData?: any;
    onChange?: (value: any[] | undefined) => void;
    customLabelStyles?: any;
}

export interface ICheckBoxState {
    optionsSelected: any[]
}

export class CheckBoxOptions extends React.Component<ICheckBoxProps, ICheckBoxState> {
    _onChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        const target = ev?.target as HTMLInputElement;
        let tempOptionsSelected = this.state.optionsSelected;
        tempOptionsSelected[target.id] = !tempOptionsSelected[target.id];

        this.setState(prevState => ({ ...prevState, optionsSelected: tempOptionsSelected }));

        if (this.props.onChange)
            this.props.onChange(tempOptionsSelected);
    };

    componentDidMount() {
        let tempValues: any = {};
        this.props.options?.forEach(opt => {
            tempValues[opt.key] = opt.isSelected;
        });
        
        this.setState(prevState => {
            return ({ ...prevState, optionsSelected: tempValues });
        });
    }

    private mapValidationData(validationData: any): any {
        let mappedValidationData: any = {};
    
        let notDefaultBehaviourForKeys: string[] = ['errorMessage', 'readOnly', 'disabled'];
        
        for(const key in validationData) {
            if (Object.prototype.hasOwnProperty.call(validationData, key)) {
                if(key === 'errorMessage') {
                    mappedValidationData[key] = L(validationData[key]);
                }
                if(key === 'readOnly' || key === 'disabled') {
                    mappedValidationData[key] = (validationData[key] === 'true' ? true : false);
                }
        
                if(!notDefaultBehaviourForKeys.includes(key)) {
                    mappedValidationData[key] = validationData[key];
                }
            }
        }
    
        return mappedValidationData;
    }

    private checkIfOptionsChanged(options: any) {
        if(options && this.state) {
            let tempOptionsSelected = this.state.optionsSelected;
            let somethingChagned = false;
            
            for(let key in options) {
                if(options.hasOwnProperty(key)) {
                    if(this.state.optionsSelected[options[key].key] !== options[key].isSelected) {
                        tempOptionsSelected[options[key].key] = options[key].isSelected;
                        somethingChagned = true;
                    }
                }
            }
            
            if(somethingChagned) {
                this.setState(prevState => ({ ...prevState, optionsSelected: tempOptionsSelected }));
            }
        }
    }

    render() {
        const { label, disabled, options, customLabelStyles, validationData, required } = this.props;
        let optList: any[] = [];

        this.checkIfOptionsChanged(this.props.options);

        let mappedValidationData = this.mapValidationData(validationData);

        if(options && options?.length > 0) {
            optList = options?.map(opt => {
                return <Checkbox key={opt.key} id={opt.key} className={classNames.checkboxInList} theme={myTheme} disabled={disabled || opt.disabled} 
                                checked={(this.state && this.state.optionsSelected[opt.key] ? this.state.optionsSelected[opt.key] : false)} 
                                label={opt.text} onChange={this._onChange} {...mappedValidationData} />
            });
        }
        
        return <>
            { (label && label.length > 0) && <LabelComponent customStyles={customLabelStyles ? customLabelStyles : {}} label={label || ''} className={classNames.checkboxLabel} required={required} displayRequired={required} /> }
            <div  className={classNames.checkboxOptionsWrapper}>
                {optList}
            </div>
        </>
    }
}
