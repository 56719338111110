import * as React from 'react';
import { ChoiceGroup, IChoiceGroupOption, mergeStyleSets } from '@fluentui/react';
import { myTheme } from '../../styles/theme';
import { LabelComponent } from './labelComponent';

const classNames = mergeStyleSets({
  choiceGroupContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
  },
  choiceGroupLabel: {
    paddingRight: '7px',
    whiteSpace: 'pre-line',
  },
  choiceGroupOptionsWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  choiceGroupOption: {
    padding: '10px 15px',
    boxSizing: 'border-box',
  },
});

export interface IChoiceGroupProps {
    label: string;
    value?: string | undefined;
    disabled?: boolean;
    required?: boolean;
    options?: IChoiceGroupOption[];
    customClassNames?: string;
    customContainerClassNames?: string;
    onChange?: (option: IChoiceGroupOption | undefined) => void;
}

export interface IChoiceGroupState {
  value: string | undefined;
}

export class ChoiceGroupBase extends React.Component<IChoiceGroupProps, IChoiceGroupState> {
  constructor(props: IChoiceGroupProps) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  componentDidMount() {
    const { value, options } = this.props;
    if (!value && options) {
      options.some((option: any) => {
        if (option.checked === true) {
          this.setState({ value: option.key });
          return true;
        }
        return false;
      });
    }
  }

  private _onChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption | undefined) => {
    if (option) this.setState({ value: option!.key });

    if (this.props.onChange) this.props.onChange(option);
  };

  render() {
    const { label, disabled, options, required, customClassNames } = this.props;
    const { value } = this.state;

    return (
      <div className={`${classNames.choiceGroupContainer} ${this.props.customContainerClassNames}`}>
        {label && label.length > 0 && (
          <LabelComponent label={label} className={classNames.choiceGroupLabel} required={required} displayRequired={required} />
        )}
        <div className={classNames.choiceGroupOptionsWrapper}>
          {options &&
            options.map((option) => (
              <div key={`${option.key}Div`} className={classNames.choiceGroupOption}>
                <ChoiceGroup
                  key={option.key}
                  disabled={disabled}
                  theme={myTheme}
                  options={[option]}
                  onChange={this._onChange}
                  required={required}
                  selectedKey={value ? value : ''}
                  className={customClassNames}
                />
              </div>
            ))}
        </div>
      </div>
    );
  }
}