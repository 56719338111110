export enum PolicyType {
    Vehicle = 0,
    Home = 1,
    Life = 2,
    Travel = 3,
    Children = 4,
    CancelTravel = 5,
    Company = 6,
    MockVehicle = 100,
    MockHome = 101
}