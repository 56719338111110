import './index.css';
import * as ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'mobx-react';
import Utils from './utils/utils';
import abpUserConfigurationService from './services/abpUserConfigurationService';
import initializeStores from './stores/storeInitializer';
import registerServiceWorker from './registerServiceWorker';
import moment from "moment-timezone";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import React from 'react';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { FluentProvider, webLightTheme } from '@fluentui/react-components';

initializeIcons();

const msalInstance = new PublicClientApplication(msalConfig);
declare var abp: any;

Utils.setLocalization();

abpUserConfigurationService.getAll().then(data => {
  Utils.extend(true, abp, data.data.result);
  abp.clock.provider = Utils.getCurrentClockProvider(data.data.result.clock.provider);

  abp.utils.setCookieValue('Abp.Auth', data.data.result.auth ? JSON.stringify(data.data.result.auth) : null, new Date(new Date().getTime() + 5 * 365 * 86400000), abp.appPath);

  moment.locale(abp.localization.currentLanguage.name);

  if (abp.clock.provider.supportsMultipleTimezone) {
    moment.tz.setDefault(abp.timing.timeZoneInfo.iana.timeZoneId);
  }

  const stores = initializeStores();

  ReactDOM.render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <Provider {...stores}>
          <BrowserRouter>
            <FluentProvider theme={webLightTheme}>
              <App />
            </FluentProvider>
          </BrowserRouter>
        </Provider>
      </MsalProvider>
    </React.StrictMode>
    ,
    document.getElementById('root') as HTMLElement
  );

  registerServiceWorker();
});
